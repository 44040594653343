.mainConditions{
  background-color: #000;
  max-width: 100vw;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
}
table{
  width: 80vw;
  border: 1px solid white;
  padding: 10px;
}
td{
  border: 1px solid #fafafa !important;
}
tr{
  border: 0.5px solid #fafafa !important;
}

.subMainCondition {
  padding: 150px;
}
@media (max-width: 600px) {
  table{
    width: 90vw;
    border: 1px solid white;
    padding: 10px;
  }
  .mainConditions{
    background-color: #000;
    max-width: 100vw;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  
  .subMainCondition {
    padding-top: 17vh;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}