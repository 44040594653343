@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@500;700&family=Rajdhani:wght@700&display=swap");
.mainUsers {
  overflow-x: hidden;
  background-color: #f9f9f9;
  max-width: 100vw;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
}
.firstUsers {
  margin-top: 90px;
  padding-top: 25vh;
  background: #1eaca7;
  /* background: radial-gradient(#002428, #000); */

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
  height: 80vh;
}

.firstLeftUsers {
  padding-bottom: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.firstUsersTitle {
  font-family: "Bebas Neue";
  align-self: center;
  text-align: left;
  margin-bottom: 30px;

  color: #fff347;
  width: 40rem;
  font-size: 6.9rem;
  line-height: 0.9;
  font-weight: 700;
}
.firstUsersSubtitle {
  /* margin-left: 15vw; */
  color: #fff;
  font-size: 2.5rem;
  width: 50rem;
}
.firstRightUsers {
  margin-left: 0vw;
  margin-bottom: 25vh;
  display: flex;

  /* height: 70vh; */
  /* width: 50vw; */
  justify-content: center;
  align-self: center;
}
.firstUsersImage {
  /* margin-right: 200px; */
  align-self: center;
  justify-content: center;
  width: 50rem;
  height: 60vh;

  /* object-fit: cover; */
}

.secondUsers {
  color: #f9f9f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.secondLeftContainerUsers {
  padding-top: 10vh;
  padding-bottom: 10vh;
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.secondRightContainerUsers {
  padding-top: 10vh;
  padding-bottom: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50vw;
}
.secondLeftUsers {
  display: flex;
  width: 700px;
  min-width: 500px;
  height: 500px;
  object-fit: cover;
  justify-content: center;
}
.secondRightUsers {
  /* background-color: #264653; */
  max-width: 700px;
}
.secondUsersTitle {
  font-family: "Bebas Neue";
  padding-right: 5vw;
  padding-bottom: 3vh;
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #006b6b;
}
.spanTitleUsers {
  color: #1eaca7;
  font-family: "Bebas Neue";
}
.secondUsersText {
  font-size: 1.5rem;
  padding-right: 5vw;
  color: #0f2c2b;
}
.thirdUsers {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.thirdLeftUsers {
  padding-left: 5vw;
  max-width: 700px;
}
.thirdRightUsers {
  display: flex;
  justify-content: center;
  width: 500px;
  min-width: 500px;
  height: 500px;
}
.slideTextUsers {
  padding-bottom: 5vh;
  padding-top: 6vh;
}

@media (max-width: 1500px) {
  .firstUsersTitle {
    align-self: center;
    text-align: left;
    margin-bottom: 30px;

    width: 30rem;
    font-size: 5rem;
    line-height: 1.2;
    font-weight: 700;
  }
  .firstUsersSubtitle {
    /* margin-left: 15vw; */

    color: #fff;
    font-size: 1.25rem;
    width: 30rem;
  }
  .firstRightUsers {
    height: 70vh;
    padding-left: 3vw;
    /* width: 50vw; */
    justify-content: center;
    align-self: center;
  }
  .firstUsersImage {
    /* margin-right: 200px; */
    width: 35rem;
    height: 40vh;
  }
  .secondLeftUsers {
    width: 500px;
    min-width: 500px;
    height: 500px;
  }
  .secondRightUsers {
    max-width: 500px;
  }
  .secondUsersTitle {
    padding-right: 5vw;
    padding-bottom: 3vh;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 700;
  }
  .secondUsersText {
    font-size: 1.25rem;
    padding-right: 5vw;
  }
}
@media (max-width: 1100px) {
  .firstUsersTitle {
    margin-top: 5vh;
    align-self: center;
    text-align: left;
    margin-bottom: 30px;
    width: 74vw;
    font-size: 4.7rem;
    line-height: 1.25;
    letter-spacing: 0;
  }
  .firstUsersSubtitle {
    /* margin-left: 15vw; */

    font-size: 1.5rem;
    width: 80vw;
  }
  .firstRightUsers {
    height: 60vh;
    /* width: 50vw; */
    justify-content: center;
    align-self: center;
  }
  .firstUsersImage {
    width: 80vw;
    height: 40vh;
  }
  .firstUsers {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding-top: 3vh;
    width: 100vw;
    height: 100vh;
  }
  .firstLeftUsers {
    width: 80vw;
    padding-bottom: 15vh;
    display: flex;
    flex-direction: column;

    align-self: center;
  }
  .firstRightUsers {
    margin-top: 20vh;
    width: 80vw;
    padding-left: 0;
    height: 30vh;
    /* width: 50vw; */
    justify-content: center;
    align-self: center;
  }
  .secondUsers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .secondLeftContainerUsers {
    padding-bottom: 10vh;
    width: 100vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .secondRightContainerUsers {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 20vh;
    width: 100vw;
  }
  .secondLeftUsers {
    width: 70vw;
    min-width: 70vw;
    height: 50vh;
  }
  .secondRightUsers {
    max-width: none;
    width: 80vw;
  }
  .secondUsersTitle {
    font-size: 4rem;
  }
  .secondUsersText {
    font-size: 1.25rem;
  }

  .thirdUsers {
    flex-direction: column-reverse;

    justify-content: center;
    align-items: center;
  }

  .thirdLeftUsers {
    margin-top: 10vh;
    padding-left: 0;
    max-width: none;
    width: 80vw;
  }
  .thirdRightUsers {
    width: 400px;
    min-width: 40vw;
    height: 50vh;
  }
  .imgStyle {
    width: 500px;
    height: 500px;
    
  }
  .slideTextUsers {
    padding-bottom: 2vh;
    padding-top: 2.7vh;
  }
  .secondLeftUsers {
    display: flex;
    width: 80vw;
    min-width: 500px;
    height: auto;
    object-fit: cover;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .firstUsers {
    justify-content: center;
    align-items: center;
    padding-top: 20vh;
    height: 100vh;
    margin-top: 0;
  }
  .firstUsersImage{
    height: auto;
  }
  .firstUsersTitle {
    width: 90vw;
    font-size: 4rem;
    text-align: center;
  }
  .firstUsersSubtitle {
    font-size: 1.25rem;
  }
  .firstRightUsers {
    padding-bottom: 0;
    padding-top: 0;
    height: auto;
    margin-top: 0;
  }
  .firstLeftUsers {
    padding-bottom: 10vh;
  }
  .imgStyle {
    width: 90vw;
    align-self: center;
    height: auto;
  }
  .secondLeftUsers {
    min-width: 70vw;
  }
  .secondUsersTitle {
    padding-right: 0;
    padding-bottom: 3vh;
  }
  .secondUsersText {
    font-size: 1.25rem;
    padding-right: 0;
  }
  .secondRightContainerUsers {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    width: 100vw;
  }

}
