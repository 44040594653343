.firstArtists {
  margin-top: 90px;
  padding-top: 25vh;
  background: #1eaca7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 80vh;
}

.firstArtistsTitle {
  font-family: "Bebas Neue";
  align-self: center;
  text-align: left;
  margin-bottom: 30px;

  color: rgb(255, 243, 71);
  width: 40rem;
  font-size: 6.9rem;
  line-height: 0.9;
  font-weight: 700;
}

.secondLeftContainerArtists {
  padding-top: 10vh;
  padding-bottom: 5vh;
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.secondLeftArtists {
  display: flex;
  width: 700px;
  min-width: 500px;
  height: 500px;
  object-fit: cover;
  object-position: 0 100%;
  overflow: hidden;
  justify-content: center;
}
.secondArtistsTitleLeft {
  font-family: "Bebas Neue";
  align-self: center;
  text-align: left;
  /* margin-bottom: 30px; */

  color: rgb(255, 243, 71);
  width: 40rem;
  font-size: 9rem;
  line-height: 0.9;
  font-weight: 700;
}
.artistText {
  font-size: 1.5rem;
    padding-right: 5vw;
    color: rgb(28, 28, 28);
    font-weight: 600;
}
.thirdLeftArtists {
  padding-left: 5vw;
  max-width: 600px;
}
.ositoImg {
  display: flex;
  object-fit: cover;
  width: 30vw;
  height: auto;
  /* justify-content: center; */
}

@media (max-width: 1500px) {
  .firstArtistsTitle {
    align-self: center;
    text-align: left;
    margin-bottom: 30px;

    width: 32.5rem;
    font-size: 5.7rem;
    line-height: 1.2;
    font-weight: 700;
  }
  .artistText {
    font-size: 1.25rem;
    padding-right: 5vw;
    color: rgb(28, 28, 28);
    font-weight: 600;
  }
}
@media (max-width: 1100px) {
  .firstArtists {
    padding-top: 5vh;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  .firstArtistsTitle {
    margin-top: 5vh;
    align-self: center;
    text-align: left;
    margin-bottom: 30px;
    width: 70vw;
    font-size: 5rem;
    line-height: 1.25;
    letter-spacing: 0;
  }
  .secondLeftContainerArtists {
    width: 100vw;
  }
  .secondLeftArtists {
    width: 80vw;
  }
  .ositoImg {
    width: 80vw;
  }

  .thirdLeftArtists {
    margin-top: 10vh;
    padding-left: 0;
    max-width: none;
    width: 80vw;
  }
}

@media (max-width: 600px) {
  .firstArtists {
    justify-content: space-around;
    padding-top: 10vh;
    height: 50rem;
  }
  .firstArtistsTitle {
    width: 90vw;
    font-size: 4rem;
    text-align: center;
  }

  .secondArtistsTitleLeft {
    font-family: "Bebas Neue";
    align-self: center;
    text-align: center;
    /* margin-bottom: 30px; */

    color: rgb(255, 243, 71);
    width: 95vw;
    font-size: 6rem;
    line-height: 0.9;
    font-weight: 700;
  }
  .thirdLeftArtists {
    margin-top: 10vh;
    padding-left: 0;
    max-width: none;
    width: 80vw;
  }
}
