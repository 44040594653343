nav {
  top: 0;
  left: 0;
  position: absolute;
  height: 90px;
  width: 100%;
  background-color: #09090a;
  z-index: 100;
  display: flex;
  align-items: center;
}
.navBar {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
a {
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.routeNavBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  gap: 5%;
}

.logoNavBar {
  margin-left: 10%;
  height: 90%;
  width: 4rem;
}

.logoNavBar img {
  width: 100%;
  height: 98%;
}

.textNavBar {
  margin-left: 5%;
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.textNavBar a,
.buttonNavBar {
  color: #fff;
}
.textNavBar .active {
  color: #fff347;
}
.buttonNavBar {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5%;
}
.buttonNavBar div {
  width: 110px;
}

.buttonNavBar button {
  transition: 1s;
  background-color: #fdfffc;
  border: none;
  width: 160px;
  padding: 0.8rem;
  cursor: pointer;
  color: #000;
  font-weight: bold;

  border-radius: 6px;
}

.buttonNavBar button:hover {
  transition: 1s;
  -webkit-box-shadow: 0px 0px 0px 0px #1814ff;
  box-shadow: 0px 0px 0px 0px #1814ff;
}

.homeNavBar {
  transition: 1s;
  cursor: pointer;
}

.logoNavBar {
  cursor: pointer;
}

.aboutNavBar {
  transition: 0.5s;
}

.aboutNavBar:hover {
  transition: 0.5s;
  cursor: pointer;
  color: #f9b41f;
}

.contactNavBar {
  transition: 0.5s;
}

.contactNavBar:hover {
  transition: 0.5s;
  cursor: pointer;
  color: #f9b41f;
}

#overlay {
  display: none;
}

#hamburger {
  margin: 4%;
}

#hamburger {
  position: absolute;
  right: 0;
  margin-right: 10%;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.25s linear;
  width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
  height: clamp(2rem, 1.4vw + 1.7rem, 5rem);
  display: flex;
  align-items: center;
}

#hamburger div,
#hamburger div:after,
#hamburger div:before {
  background-color: #fafafa;
  border-radius: 10px;
  width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
  height: clamp(0.2rem, 0.3vw + 0.1rem, 0.8rem);
  transition: all 0.15s linear;
}

#hamburger div:before,
#hamburger div:after {
  content: "";
  position: absolute;
}

#hamburger div:before {
  transform: translateY(-200%);
}

#hamburger div:after {
  transform: translateY(200%);
}

#hamburger.open div {
  background: transparent;
}

#hamburger.open div:before {
  transform: rotate(45deg);
}

#hamburger.open div:after {
  transform: rotate(-45deg);
}

@media (min-width: 1300px) {
  #hamburger {
    display: none;
  }
  #overlay {
    display: none;
  }
}

@media (max-width: 1300px) {
  .routeNavBar {
    transition: 1s;
    display: none;
  }
  .hamburger {
    display: flex;
  }
  .navBar {
    transition: 1s;
    width: 100%;
    margin-left: 0%;
    background-color: #09090a;
  }

  #overlay {
    position: absolute;
    width: 100%;
    margin-top: 79px;
    height: auto;
    background-color: #09090a;
    z-index: 2;
    transform: translateY(-100%);
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #overlay.menu {
    transform: translateY(0%);
  }

  .overlayTitle {
    text-decoration: none;
    color: #fafafa;
    margin: 2%;
    opacity: 1;
    text-align: left;
    transition: opacity 0.2s linear;
  }

  .overlayLinks div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1%;
  }
  .overlayLinks a {
    margin-top: 0;
  }
  .overlayLinks {
    width: 100%;
  }

  .active {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #1f90f9;
  }

  .overlayLinks .overlaySelector {
    height: 49px;
    background-color: #ffff;
    width: 5px;
    border-radius: 4px;
  }
  .active .overlayTitle {
    color: #1f90f9;
  }
  .active .overlaySelector {
    background-color: #1f90f9;
  }

  #overlay a:hover {
    opacity: 1;
  }

  .overlayButton {
    height: auto;
    width: 40%;
    margin: auto;
    margin-bottom: 2%;
    justify-self: center;
    transition: 1s;
    background-color: #fdfffc;
    border: none;
    border-radius: 0.5rem;
    padding: 0.6rem;
    cursor: pointer;
    color: #000;
    font-weight: bold;
    font-size: 24px;
  }
}

@media (max-width: 900px) {
  .overlayButton {
    width: 70%;
    margin-bottom: 5%;
  }
  .overlayLinks a {
    margin-top: 5%;
  }
}
