footer {
  padding-top: 5vh;
  height: auto;
  background: #09090a;
  color: #fff;
  z-index: 100;

}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  
}

.routeFooter {
  margin-left: 5%;
  margin-bottom: 2%;
  height: auto;
  width: 33%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.routeFooter div {
  margin-top: 8%;
}
.routeFooter div:hover {
  cursor: pointer;
}

.contactFooter {
  height: 100%;
  width: 33%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contactFooter p{
  text-align: center;
  width: 20vw;
  line-height: 1.2;
}

.contactFooterEmail{
  margin-top: 5%;
  font-weight: 700;
  font-size: 1.25rem;
}

.inputFooter {
  text-indent: 20px;
}

.suscribeFooter {
  background: #2091f9;
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.midFooter {
  display: flex;
  height: 100%;
  width: 33%;
  margin-right: 6%;
}
.socialText {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.socialFooter {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.socialImages {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10%;
  margin-top: 6%;
}
.instagram{
  cursor: pointer;
}

.textFooter {
  width: 100%;
  bottom: 0;
  position: relative;
  height: auto;
  text-align: center;
}

@media (max-width: 1200px) {
  .contactFooter {
    margin-right: 4%;
  }
  .contactFooter form {
    width: auto;
  }

  .contactFooter form input {
    margin-bottom: 4%;
    width: 280px;
    height: 54px;
  }
}

@media (max-width: 1000px) {
  .footer {
    width: 100%;
    flex-direction: column-reverse;
    align-items: space-between;
    justify-content: center;
  }

  .routeFooter {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 6%;
  }
  .routeFooter div {
    width: 20%;
    font-size: 14px;
    font-weight: normal;
  }

.contactFooter p{
  width: 80vw;
}

  .contactFooter,
  .midFooter,
  .socialFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0;
    margin-top: 2%;
  margin-bottom: 2%;
  }
  .routeFooter {
    margin-left: 0;
    gap: 5vw;
    align-items: baseline;
    text-align: center;
  }
  .routeFooter div{
    width: 25vw;
  }
  .midFooter {
    margin-right: 0;
  }
  .contactFooter{
    margin-top: 0%;
    margin-bottom: 5%;
  }
  .socialImages {
    justify-content: center;
    margin-top: 7%;
  }

  
  .textFooter {
    padding-bottom: 3%;
    margin-top: 10%;
  }
}
