@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
.red {
  color: #e71d36;
}
body {
  background-color: #000 !important;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  overflow-x: hidden;
}
.main {
  background-color: #000;
  max-width: 100vw;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
}
.first {
  background: #002428;
  background: radial-gradient(#002428, #000);
  padding-top: 10vh;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.firstLeft {
  padding-left: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
span {
  color: #ff9f1c;
  font-family: Arial, Helvetica, sans-serif;
}
.firstSpan {
  font-weight: 500;
  font-size: 1rem;
  color: rgba(253, 255, 252, 0.898);
}

.firstTitle {
  align-self: center;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 1.5rem;
  color: #fdfffc;
  width: 600px;
  letter-spacing: 0.5px;
  line-height: 36px;
}
.firstButton {
  align-self: flex-start;
  background-color: #fdfffc;
  border: none;
  padding: 0.8rem;
  padding-left: 50px;
  padding-right: 50px;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  font-size: 20px;
  border-radius: 6px;
}
.firstButton:hover {
  background-color: #c7c3ba;
  transition: 0.25s all;
}
.firstRight {
  display: flex;
  position: relative;
  right: 0;
  width: 1100px;
  height: 70vh;
  align-self: center;
  object-fit: contain;
  justify-content: left;
  overflow: hidden;
}

p {
  color: #fdfffc;
}
.second {
  height: auto;
  padding-top: 10vh;
  display: flex;
  flex-direction: row;
}
.overflow {
  overflow-x: hidden;
}
.slideText {
  color: transparent;
  position: relative;
  z-index: -1;
  white-space: nowrap;
  font-size: 7vw;
  text-align: center;
  line-height: 1.25;
  margin: 0;
  opacity: 0.4;
  -webkit-text-stroke: 1px #fdfffc;
  letter-spacing: -5px;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
}

.third {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90vw;
  align-self: center;
  gap: 5vh;
  /* background-color: brown; */
}
.thirdRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90vw;
  margin-top: 6rem;
}
.thirdRowTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30rem;
  margin-left: 120px;
  margin-right: 120px;
}
.number {
  color: transparent;
  position: absolute;
  left: -5rem;
  font-size: 12rem;
  z-index: -1;
  opacity: 0.3;
  -webkit-text-stroke: 1px #fdfffc;
  letter-spacing: -5px;
}
.thirdTitle {
  position: relative;
  font-weight: bold;
  align-self: center;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 3rem;
  color: #fdfffc;
}
.thirdText {
  font-weight: 600;
  color: rgba(253, 255, 252, 0.7);
  line-height: 1.5;
  font-size: 1.5rem;
}

.thirdRowImageContainer {
  position: relative;
  display: flex;
  width: 50rem;
  height: 30vh;
  align-self: center;
  /* object-fit: cover; */
  justify-content: center;
  overflow: hidden;
}

.thirdRowInversed {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin-top: 6rem;
}

.forth {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90vw;
  align-self: center;
}
.forthText {
  width: 50vw;
  text-align: center;
  font-weight: bold;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 2.5rem;
  color: #fdfffc;
}

@media (max-width: 1300px) {
  .firstLeft {
    padding-left: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .firstTitle {
    width: 40vw;
    font-size: 1.25rem;
  }

  .firstRight {
    height: 50vh;

    right: -10%;
  }
  .thirdRowTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40rem;
    margin-left: 40px;
    margin-right: 40px;
  }
  .forthText {
    font-size: 2rem;
    width: 65vw;
  }
}

@media (max-width: 950px) {
  .first {
    height: auto;
    margin-top: 10%;
    flex-direction: column;
    width: 100vw;
    gap: 10vh;
  }
  .firstLeft {
    margin-top: 5vh;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .firstTitle {
    width: 85vw;
  }
  .firstSpan {
    font-size: 1rem;
    padding-left: 9vw;
    text-align: left;
  }
  .firstButton {
    align-self: center;
    padding: 0.8rem;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 20px;
  }
  .firstRight {
    height: auto;
    width: 100%;
    justify-content: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .firstImg {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
  /* .firstRight {
    display: flex;
    position: relative;
    right: 0;
    width: 1100px;
    background-color: aqua;
    height: 70vh;

    align-self: center;
    object-fit: contain;

    overflow: hidden;
  } */

  .second {
    height: auto;
    padding-top: 15vh;
    display: flex;
    flex-direction: row;
  }

  .slideText {
    font-size: 2rem;
    text-align: center;
    line-height: 1.5;
    margin: 0;
    letter-spacing: 1px;
  }
  .third {
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    gap: 5vh;
    /* background-color: brown; */
  }
  .thirdRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    gap: 5vh;
  }
  .thirdRowTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    text-align: left;
    width: 80vw;
    margin-left: 0;
    margin-right: 0;
  }
  .number {
    color: transparent;
    position: absolute;
    left: -1.5rem;
    top: -2rem;
    font-size: 5rem;
    z-index: -1;

    letter-spacing: -5px;
  }
  .thirdTitle {
    align-self: center;
    text-align: left;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 2rem;
  }
  .thirdText {
    font-size: 0.8rem;
    font-weight: 500;
    color: rgba(253, 255, 252, 0.7);
    line-height: 1.5;
    font-size: 1.25rem;
  }
  .thirdRowInversed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    gap: 5vh;
  }
  .thirdRowImageContainer {
    width: 100vw;
    /* Esto se deja en auto cuando meta img */
    height: 30vh;
  }

  .forthText {
    font-size: 1.25rem;
    width: 80vw;
  }
}
